(function () {
	'use strict';

	angular
		.module('app')
		.factory('onboarding', [
			'manageEventSources',
			'hash',
			'utilities',
			onboarding,
		]);

	function onboarding(manageEventSources, hash, utilities) {
		return {
			welcome: welcome,
			authorizeAccount: authorizeAccount,
		};

		function welcome(type) {
			// Template will use the onboarding route as the identifier (welcome-filemaker.html)

			var today = new Date();
			today.setHours(0, 0, 0, 0);
			today = today.getTime();

			var templatePath =
				type === 'filemakerclient' || type === 'filemaker'
					? 'app/onboarding/welcome/welcome-' + type + '.html'
					: 'app/onboarding/welcome/welcome-default.html';
			var hashValue = hash.get();

			var expires = hashValue.expiresDate
				? Math.abs(
						utilities.numberToDays(hashValue.expiresDate - today)
				  )
				: 30;

			//Prevent trial dialogs
			hash.setPreventDialogs(true);

			var onboardingData = {
				type: type,
				expires: expires,
				templatePath: templatePath,
			};

			var popover = {
				id: 'authorize',
				controller: 'OnboardingCtrl',
				container: $('body'),
				type: 'modal', // modal or popover
				width: 750,
				// positionX: e.pageX,
				// positionY: e.pageY,
				data: onboardingData,
				dataTitle: 'onboardingData',
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: function () {
					//Unset prevention of trial dialogs
					hash.setPreventDialogs(false);
				},
				show: true,
			};

			//Show popover
			utilities.popover(
				popover,
				'<div ng-include="\'' +
					'app/onboarding/welcome/welcome.html' +
					'\'"></div>'
			);
		}

		function authorizeAccount(type) {
			var today = new Date();
			today.setHours(0, 0, 0, 0);
			today = today.getTime();

			var templateID;

			if (type === 'google') {
				templateID = 3;
			} else if (type === 'office365') {
				templateID = 5;
			}

			var sourceTemplate = manageEventSources.getTemplate(templateID);

			var hashValue = hash.get();

			var expires = hashValue.expiresDate
				? Math.abs(
						utilities.numberToDays(hashValue.expiresDate - today)
				  )
				: 30;

			//Prevent trial dialogs
			hash.setPreventDialogs(true);

			var onboardingData = {
				type: type,
				sourceTemplate: sourceTemplate,
				expires: expires,
			};

			var popover = {
				id: 'authorize',
				controller: 'OnboardingCtrl',
				container: $('body'),
				type: 'modal', // modal or popover
				width: 700,
				// positionX: e.pageX,
				// positionY: e.pageY,
				data: onboardingData,
				dataTitle: 'onboardingData',
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: function () {
					//Unset prevention of trial dialogs
					hash.setPreventDialogs(false);
				},
				show: true,
			};

			//Show popover
			utilities.popover(
				popover,
				'<div ng-include="\'app/onboarding/authorize-source.html\'"></div>'
			);
		}
	}
})();
